<template>
  <v-app>
    <router-view />
    <v-dialog
      :value="updateExists"
      persistent
      max-width="290"
    >
      <v-card>
        <v-row
          align="center"
          justify="center"
          class="pt-5 pb-5 ma-0"
        >
          <v-img
            src="./assets/hello.svg"
            max-width="50"
          />
        </v-row>
        <v-card-title class="text-h5">
          New version
        </v-card-title>
        <v-card-text>We've made a few changes to the app while you were away. Please hit the refresh button to fetch the latest version.</v-card-text>
        <v-card-actions>
          <v-btn
            x-large
            block
            color="primary"
            text
            @click="refreshApp"
          >
            Refresh
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false
  }),
  created () {
    // Listen for swUpdated event and display refresh snackbar as required
    document.addEventListener(
      'swUpdated', this.showRefreshMessage, { once: true }
    )

    if ('serviceWorker' in navigator) {
      // Refresh all open app tabs when a new service worker is installed
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          window.location.reload()
        }
      )
    }
  },
  methods: {
    showRefreshMessage (e) {
      // store the ServiceWorkerRegistration instance for later use
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      // protect against missing registration.waiting
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
</script>

<style>
html,
body {
  height: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
}
.v-application,
.v-application--wrap {
  height: 100% !important;
  min-height: 100% !important;
}

/* force gray-100 background for light application */
.theme--light.v-application {
  background: #f7fafc !important;
}
/* force gray-100 background for container */
.container {
  background: #f7fafc !important;
}

/* force white background for dialog */
.v-application .v-dialog {
  background-color: white !important;
}

/* workaround for snackbar not visible (ref: https://github.com/vuetifyjs/vuetify/issues/11781) */
.v-snack.v-snack--bottom {
  top: initial;
}

/* change default font */
html body .v-application,
html body .v-application .text-h1,
html body .v-application .text-h2,
html body .v-application .text-h3,
html body .v-application .text-h4,
html body .v-application .text-h5,
html body .v-application .text-h6,
html body .v-application .text-subtitle-1,
html body .v-application .text-subtitle-2,
html body .v-application .text-body-1,
html body .v-application .text-body-2,
html body .v-application .text-caption,
html body .v-application .text-overline {
  font-family: 'Inter', 'Roboto', sans-serif !important;
}

/* hide google maps copyright text */
.gmnoprint {
  display: none !important;
}

/* override: remove click effect on click finish */
button:hover:before,
button:focus:before {
  background-color: white !important;
}

/* prevent scrolling background when nav drawer is open */
.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* custom link styling  */
.internal-link {
  text-decoration: underline !important;
  text-decoration-color: #18a5a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.unstyled-link {
  text-decoration: none !important;
  color: inherit !important;
}

/* customize dialogs globally */
.v-dialog {
  border-radius: 15px !important;
}
.v-dialog img {
  display: flex !important;
  align-items: center !important;
  margin: 0 auto !important;
  padding-top: 20px !important;
  width: 80px !important;
}
.v-dialog .v-card__text {
  color: rgba(0, 0, 0, 0.87) !important;
}
.dialog-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>
