import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { SENTRY_DSN, VERSION, POSTHOG_API_KEY } from './config'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'

const isProd = process.env.NODE_ENV === 'production'
Vue.config.productionTip = false

// enable sentry error monitoring
if (isProd) {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    release: `tilt-user-pwa@${VERSION}`
  })
}

if (process.env.NODE_ENV === 'production') {
  // posthog
  posthog.init(POSTHOG_API_KEY, { api_host: 'https://app.posthog.com', autocapture: false })
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// send page view to PostHog on router change
router.afterEach((to, from) => {
  if (process.env.NODE_ENV === 'production') {
    posthog.capture('$pageview')
  }
})
